<div
  *ngFor="let review of reviews; let i = index; let even = even; let odd = odd"
>
  <div
    class="row featurette mb-4 wow"
    data-wow-delay="delay"
    [ngClass]="even ? 'fadeInLeft' : 'fadeInRight'"
  >
    <div
      class="col-md-7 my-auto order-2 text-center"
      [ngClass]="even ? 'order-md-2 text-md-start' : 'order-md-1 text-md-end'"
    >
      <h2 class="featurette-heading">
        {{ review.reviewer }}
        <span class="text-muted">{{ review.company }}</span>
      </h2>

      <q class="lead" [innerHTML]="review.bodyText"></q>
    </div>
    <div
      *ngIf="review.imgSrc"
      class="col-md-5 order-1 d-flex justify-content-center"
      [ngClass]="!even ? 'order-md-2' : 'order-md-1'"
    >
      <img
        class="featurette-image img-fluid mx-auto my-auto"
        src="{{ review.imgSrc }}"
        role="img"
      />
    </div>
  </div>
  <hr class="mx-auto my-5" />
</div>
