import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-section-hero",
  templateUrl: "./section-hero.component.html",
  styleUrls: ["./section-hero.component.scss"],
})
export class SectionHeroComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
