<div class="card h-100 p-4 wow flipInX" data-wow-delay="delay">
  <div class="card-img pb-3">
    <i [class]="iconCss"></i>
    <img />
  </div>
  <div class="card-body">
    <h3 class="py-3">{{ title }}</h3>
    <p class="display-7 pre-wrap" [innerHTML]="bodytext"></p>
  </div>
</div>
