import { Component, OnInit } from "@angular/core";
import { Post } from "src/app/models/posts";
import { BlogService } from "src/app/services/blog.service";

@Component({
  selector: "app-blog-mini-view",
  templateUrl: "./blog-mini-view.component.html",
  styleUrls: ["./blog-mini-view.component.scss"],
})
export class BlogMiniViewComponent implements OnInit {
  posts: Post[];

  constructor(private blogService: BlogService) {
    this.posts = blogService.GetPosts([], undefined, 3, 0);
  }

  ngOnInit(): void {}
}
