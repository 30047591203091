<div class="custom-color">
<div id="multi-item-example" class="carousel slide carousel-multi-item mx-auto" data-ride="carousel">


    <!--Controls-->
  <div class="controls-top text-center">
    <a class="btn-floating p-3" href="#multi-item-example" data-slide="prev"><i class="fas fa-chevron-left"></i></a>
    <a class="btn-floating p-3" href="#multi-item-example" data-slide="next"><i
        class="fas fa-chevron-right"></i></a>
  </div>
  <!--/.Controls-->

  <!--Indicators-->
  <ol class="carousel-indicators">
    <li data-target="#multi-item-example" data-slide-to="0" class="active"></li>
    <li data-target="#multi-item-example" data-slide-to="1"></li>
    
  </ol>
  <!--/.Indicators-->
  
    <!-- The slideshow -->

    <div class="carousel-inner">
    
    <div class="carousel-item active">

        <div class="col-md-3 float-left my-5 mx-auto">
         <div class="card mb-2">
            <img class="card-img-top"
              src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
            <div class="card-body">
              <h4 class="card-title">Service used</h4>
              <p class="card-text">Service description</p>
            </div>
          </div>
        </div>
  
        <div class="col-md-3 float-left my-5 mx-auto">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>
  
           <div class="col-md-3 float-left my-5 mx-auto">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>
           <div class="col-md-3 float-left my-5 mx-auto">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>
      
      </div>
      <!--/.First slide-->
  
      <!--Second slide-->
      <div class="carousel-item">
  
        <div class="col-md-3" style="float:left">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>
  
           <div class="col-md-3" style="float:left">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>
  
           <div class="col-md-3" style="float:left">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>

           <div class="col-md-3 float-left my-5 mx-auto">
            <div class="card mb-2">
               <img class="card-img-top"
                 src="./assets/kerry-images/ship-lift.jpg" alt="ship lift">
               <div class="card-body">
                 <h4 class="card-title">Service used</h4>
                 <p class="card-text">Service description</p>
               </div>
             </div>
           </div>
        
        
  
      </div>
      </div>
  
  
  </div>

  