<div class="row px-2">
  <div *ngFor="let photo of photos" class="col-12 col-md-4 p-2">
    <img
      class="img-fluid rounded"
      [src]="photo.imgSrc"
      alt="{{ photo.title }}"
      title="{{ photo.title }}"
    />
  </div>
</div>
