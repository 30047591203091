import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dry-d',
  templateUrl: './dry-d.component.html',
  styleUrls: ['./dry-d.component.scss']
})
export class DryDComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
