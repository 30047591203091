import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { MatTabsModule } from "@angular/material/tabs";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataService } from "./services/data.service";
import { ArticleComponent } from "./components/articles/article/article.component";
import { CwsLogoComponent } from "./svg/cws-logo/cws-logo.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./pages/home/home.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ArticleCenterComponent } from "./components/articles/article-center/article-center.component";
import { CardComponent } from "./components/cards/card/card.component";
import { CardsComponent } from "./components/cards/cards/cards.component";
import { HttpClientModule } from "@angular/common/http";
import { TermsComponent } from "./pages/terms/terms.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { GdprComponent } from "./pages/gdpr/gdpr.component";
import { IntroComponent } from "./components/intros/intro/intro.component";
import { BannerParallaxComponent } from "./components/banners/banner-parallax/banner-parallax.component";
import { FeatureComponent } from "./components/features/feature/feature.component";
import { FeatureCardsComponent } from "./components/features/feature-cards/feature-cards.component";
import { FeatureCardComponent } from "./components/features/feature-card/feature-card.component";
import { TimelineWorkflowComponent } from "./components/timelines/timeline-workflow/timeline-workflow.component";
import { ContactFormComponent } from "./components/forms/contact-form/contact-form.component";
import { ModalPopupComponent } from "./components/popups/modal-popup/modal-popup.component";
import { QuoteComponent } from "./components/articles/quote/quote.component";
import { ContactComponent } from "./components/banners/contact/contact.component";
import { FeatureCardImageComponent } from "./components/features/feature-card-image/feature-card-image.component";
import { PayoptionsContentComponent } from "./components/content/payoptions-content/payoptions-content.component";
import { DifferentlyContentComponent } from "./components/content/differently-content/differently-content.component";
import { TeamComponent } from "./components/features/team/team.component";
import { TeamsComponent } from "./components/features/teams/teams.component";
import { TabComponent } from "./components/tabs/tab/tab.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogPostComponent } from "./components/blogs/blog-post/blog-post.component";
import { BlogMiniViewComponent } from "./components/blogs/blog-mini-view/blog-mini-view.component";
import { BlogHeaderComponent } from "./components/blogs/blog-header/blog-header.component";
import { BlogCommentsComponent } from "./components/blogs/blog-comments/blog-comments.component";
import { BlogCommentComponent } from "./components/blogs/blog-comment/blog-comment.component";
import { PhotoBannerComponent } from "./components/banners/photo-banner/photo-banner.component";
import { DryDComponent } from "./dry-d/dry-d.component";
import { TextBannerComponent } from "./text-banner/text-banner.component";
import { ProjectMComponent } from "./project-m/project-m.component";
import { CardCarouselComponent } from "./components/card-carousel/card-carousel.component";
import { BigCarouselComponent } from "./components/big-carousel/big-carousel.component";
import { Iso9001Component } from "./pages/iso9001/iso9001.component";
import { WeldingComponent } from "./pages/Services/welding/welding.component";
import { MarineEngineeringComponent } from "./pages/Services/marine-engineering/marine-engineering.component";
import { MachineShopComponent } from "./pages/Services/machine-shop/machine-shop.component";
import { DryDockingComponent } from "./pages/Services/dry-docking/dry-docking.component";
import { SubseaEngineeringComponent } from "./pages/Services/subsea-engineering/subsea-engineering.component";
import { DredgePipesComponent } from "./pages/Services/dredge-pipes/dredge-pipes.component";
import { HoursBannerComponent } from "./components/banners/hours-banner/hours-banner.component";
import { BlogFullPostComponent } from "./pages/blog-full-post/blog-full-post.component";
import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { GalleryItemComponent } from "./components/gallery-item/gallery-item.component";
import { GalleryFullComponent } from "./components/gallery-full/gallery-full.component";
import { NgxMasonryModule } from "ngx-masonry";
import { GalleryPageComponent } from "./pages/gallery-page/gallery-page.component";
import { CheckboxSelectorComponent } from "./components/checkbox-selector/checkbox-selector.component";
import { SectionHeroComponent } from "./components/section-hero/section-hero.component";
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { SlidyReviewComponent } from './components/articles/slidy-review/slidy-review.component';
import { ServiceTemplateComponent } from './components/service-template/service-template.component';

@NgModule({
  declarations: [
    AppComponent,
    ArticleComponent,
    CwsLogoComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ArticleCenterComponent,
    CardComponent,
    CardsComponent,
    TermsComponent,
    PrivacyComponent,
    GdprComponent,
    IntroComponent,
    BannerParallaxComponent,
    FeatureComponent,
    FeatureCardComponent,
    FeatureCardsComponent,
    TimelineWorkflowComponent,
    ContactFormComponent,
    ModalPopupComponent,
    QuoteComponent,
    ContactComponent,
    FeatureCardImageComponent,
    PayoptionsContentComponent,
    DifferentlyContentComponent,
    TeamComponent,
    TeamsComponent,
    TabComponent,
    BlogComponent,
    BlogPostComponent,
    BlogMiniViewComponent,
    BlogHeaderComponent,
    BlogCommentsComponent,
    BlogCommentComponent,
    PhotoBannerComponent,
    DryDComponent,
    TextBannerComponent,
    ProjectMComponent,
    CardCarouselComponent,
    BigCarouselComponent,
    Iso9001Component,
    WeldingComponent,
    MarineEngineeringComponent,
    MachineShopComponent,
    DryDockingComponent,
    SubseaEngineeringComponent,
    DredgePipesComponent,
    HoursBannerComponent,
    BlogFullPostComponent,
    ContactPageComponent,
    GalleryComponent,
    GalleryItemComponent,
    GalleryFullComponent,
    GalleryPageComponent,
    CheckboxSelectorComponent,
    SectionHeroComponent,
    ReviewsComponent,
    SlidyReviewComponent,
    ServiceTemplateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgxMasonryModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [DataService],
  bootstrap: [AppComponent],
})
export class AppModule {}
