import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private ar: ActivatedRoute) {}
  faCoffee = faCoffee;
  isHomePage: boolean = true;

  ngOnInit(): void {
    this.isHomePage = this.ar.outlet === "primary";
  }
}
