<div class="container page-content">
  <div class="row">
    <div class="col-12 wow fadeInUp">
        <h1 class="text-center">ISO9001 Policy</h1>
        <p class="text-center">Last updated 22/06/2022</p>
    </div>
    <div class="col-12 wow fadeInUp">
      <h2>It is the policy of Kerry Marine Engineering to:</h2>
    </div>

    <div class="col-12 wow fadeInUp">
        <p>
          strive to satisfy the requirements of all of our customers, stakeholders and interested parties whenever possible, meeting and exceeding their expectations;
        </p>
    </div>

    <div class="col-12 wow fadeInUp">
        <p>
          comply with all legal requirements, codes of practice and all other requirements applicable to our activities;
        </p>
    </div>

    <div class="col-12 wow fadeInUp">
        <p>
          the reduction of hazards, prevention of injury, ill health and pollution;
        </p>
    </div>

    <div class="col-12 wow fadeInUp">
        <p>
          provide all the resources of equipment, trained and competent staff and any other requirements to enable these objectives to be met;
        </p>
    </div>

    <div class="col-12 wow fadeInUp">
      <p>
        ensure that all employees are made aware of their individual obligations in respect of this quality policy;
      </p>
    </div>

    <div class="col-12 wow fadeInUp">
      <p>
        maintain a management system that will achieve these objectives and seek continual improvement in the effectiveness and performance of our management system based on “risk”.
      </p>
    </div>

    <div class="col-12 wow fadeInUp">
      <p>If you have any questions regarding our quality policy please do not hesitate to contact us via our <a class="text-black" [routerLink]='"/home"' [fragment]="'ContactForm'">contact form</a> or on any of the contact details listed on the footer of this page</p>
    </div>

    <div class="col-12 wow fadeInUp">
      <p>
        Customer service is an essential part of the quality process and to ensure this is fulfilled, all employees receive training to ensure awareness and understanding of quality and its impact on customer service.
      </p>
    </div>

    <div class="col-12 wow fadeInUp">
      <p>
        To ensure the company maintains its awareness for continuous improvement, the quality system is regularly reviewed by “Top Management” to ensure it remains appropriate and suitable to our business. The Quality System is subject to both internal and external annual audits.
      </p>
    </div>
    <hr>
  </div>
  </div>
