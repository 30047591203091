<div class="custom-color">
<mdb-carousel #carousel class=" custom-color carousel slide" [interval]="'5000'" [animation]="'slide'" [noPause]="true">
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/work.jpg" alt="Kerry Marine Van">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Light mask</h3>
            <p>First text</p>
        </div>-->
    </mdb-carousel-item>
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/ship-lift-3.jpg" alt="ship lift">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Light mask</h3>
            <p>First text</p>
        </div>-->
    </mdb-carousel-item>
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/structure.jpg" alt="structure">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Strong mask</h3>
            <p>Secondary text</p>
        </div>-->
    </mdb-carousel-item>
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/autopride.jpg" alt="autopride">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Slight mask</h3>
            <p>Third text</p>
        </div>-->
    </mdb-carousel-item>
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/clipper.jpg" alt="clipper">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Slight mask</h3>
            <p>Third text</p>
        </div>-->
    </mdb-carousel-item>
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/weld-2.jpg" alt="welding">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Slight mask</h3>
            <p>Third text</p>
        </div>-->
    </mdb-carousel-item>


    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/close-up-1.jpg" alt="close up">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Slight mask</h3>
            <p>Third text</p>
        </div>-->
    </mdb-carousel-item>
    <mdb-carousel-item>
        <div class="view w-100">
            <img class="d-block w-100" src="./assets/kerry-images/barge.jpg" alt="barge">
            <div class="waves-light" mdbWavesEffect></div>
        </div>
        <!--<div class="carousel-caption">
            <h3 class="h3-responsive">Slight mask</h3>
            <p>Third text</p>
        </div>-->
    </mdb-carousel-item>

    <mdb-carousel-item>
      <div class="view w-100">
          <img class="d-block w-100" src="./assets/kerry-images/Gosport.jpg" alt="barge">
          <div class="waves-light" mdbWavesEffect></div>
      </div>
      <!--<div class="carousel-caption">
          <h3 class="h3-responsive">Slight mask</h3>
          <p>Third text</p>
      </div>-->
  </mdb-carousel-item>

  <mdb-carousel-item>
    <div class="view w-100">
        <img class="d-block w-100" src="./assets/kerry-images/Maersk.jpg" alt="barge">
        <div class="waves-light" mdbWavesEffect></div>
    </div>
    <!--<div class="carousel-caption">
        <h3 class="h3-responsive">Slight mask</h3>
        <p>Third text</p>
    </div>-->
</mdb-carousel-item>

<mdb-carousel-item>
  <div class="view w-100">
      <img class="d-block w-100" src="./assets/kerry-images/Welding.jpg" alt="barge">
      <div class="waves-light" mdbWavesEffect></div>
  </div>
  <!--<div class="carousel-caption">
      <h3 class="h3-responsive">Slight mask</h3>
      <p>Third text</p>
  </div>-->
</mdb-carousel-item>
</mdb-carousel>
</div>
