import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  showPopup: boolean;

  @Input()
  btnCancelText: string;
  btnShowCancel: boolean;
  @Output() cancelClicked = new EventEmitter();

  @Input()
  btnActionText: string;
  btnShowAction: boolean;
  @Output() actionClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.btnShowCancel = this.btnCancelText !== undefined && this.btnCancelText.length > 0;
    this.btnShowAction = this.btnActionText !== undefined && this.btnActionText.length > 0;
  }

  BtnActionClicked() {
    this.actionClicked.emit();
  }

  BtnCancelClicked() {
    this.cancelClicked.emit();
  }
}
