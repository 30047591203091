<div class="container page-content">
<div class="row">
  <div class="col-12 wow fadeInUp">
      <h1 class="text-center">Privacy Policy</h1>
      <p class="text-center">Last updated 20/04/2020</p>
  </div>
  <div class="col-12 wow fadeInUp">
    <h2>Who are we</h2>
      <p>
        We are Custom Web Solutions Ltd, Company Number 12519332, referred to throughout this document as "Custom Web Solutions", "We" or "Us".
      </p>
    </div>
<hr>
  <div class="col-12 wow fadeInUp">
    <h2>How we will collect your information</h2>
      <p>
        Your data will be collected by you telling us directly when seeking a quote, making an enquiry or by creating an online account.
      </p>
      <p>
        Any data collected will only be kept for as long as necessary to fulfil the purposes we collected it for.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>How we will use your information</h2>
      <p>
        We respect your privacy and will never share your data unless requested by law or by you. The data we collect from you including name, contact details and any information required for your project will only be used for those purposes and to maintain your services with us and represent our work on our own portfolio. We will never share your data with a third party unless requested or authorised by you specifically for your project.
      </p>
      <p>
        Any Payment details will not be stored by us and will be dealt with by our payment system directly (Go Cardless)
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>Access to your information and corrections</h2>
      <p>
        Following GDPR law you have the right to access, update or request erasure of all data we have received from you. Should you wish to check what information we have or if you would like to exercise your right to erasure please contact us by emailing <a href="mailto:charley@customwebsolutions.ltd">charley@customwebsolutions.ltd</a>
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>Cookie</h2>
      <p>
        Cookies are small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences, store information for things like shopping baskets, and provide anonymised tracking data to third party applications like Google Analytics. As a rule, cookies will make your browsing experience better. However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser.
      </p>
      <h3>
        Our website may place the following cookies:
      </h3>
      <p><b>Analytical/performance cookies:</b></p>
      <p>
        They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
      </p>
      <p><b>Targeting cookies:</b></p>
      <p>These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.</p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>Other Websites</h2>
      <p>
        Our website may contain links to other websites in places such as blogs. This Privacy Policy only applies to Custom Web Solutions website, so please check their privacy policies for any external links to other websites you visit as a result of clicking a link to an external website.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>Changes to our Privacy Policy</h2>
      <p>
        Our Privacy Policy is under regular review and we will place any updates on this web page. This Privacy Policy was last update on the 21/04/20.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>How to contact us</h2>
      <p>
        Please contact us if you have any questions about our Privacy Policy or information we hold about you in the following manner:<br>
        <b>Tel:</b> 07772 925024<br>
        <b>Emails:</b> <a href="mailto:charley@customwebsolutions.ltd"> charley@customwebsolutions.ltd</a>
      </p>

  </div>
  <hr>
</div>
</div>
