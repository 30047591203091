import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CheckBoxItem } from "../../models/CheckBoxItem";

@Component({
  selector: "app-checkbox-selector",
  templateUrl: "./checkbox-selector.component.html",
  styleUrls: ["./checkbox-selector.component.scss"],
})
export class CheckboxSelectorComponent implements OnInit {
  @Input() checkList: CheckBoxItem[];
  @Output() OnSelected = new EventEmitter<CheckBoxItem[]>();

  ShowAll = true;

  constructor() {}

  ngOnInit(): void {}

  OnSelect(item: CheckBoxItem) {
    this.checkList.find((f) => f.name === item.name).isSelected =
      item.isSelected;
    this.OnSelected.emit(this.checkList);
  }

  SelectAll() {
    this.checkList.forEach((element) => {
      element.isSelected = this.ShowAll;
    });
    this.OnSelected.emit(this.checkList);
  }
}
