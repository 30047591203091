import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IGallery } from "src/app/models/IGallery";

@Component({
  selector: "app-gallery-item",
  templateUrl: "./gallery-item.component.html",
  styleUrls: ["./gallery-item.component.scss"],
})
export class GalleryItemComponent implements OnInit {
  safeSRC: any;

  @Input() item: IGallery;
  @Output() itemClicked = new EventEmitter<IGallery>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.safeSRC = this.sanitizer.bypassSecurityTrustUrl(
      "assets/gallery/thumbs/" + this.item.imageURL
    );
  }

  GalleryItemClicked() {
    this.itemClicked.emit(this.item);
  }
}
