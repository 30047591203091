<div class="container blue-kme-300-bk text-white text-center p-3 my-3">
  <div class="row">
    <div class="col-12 p-3">
      <h1 class="fs-2">Kerry Marine Engineering Ltd Blog</h1>
      <p class="fs-5">
        Our views on all things marine engineering from helpful tips to the
        latest industry news.
      </p>
    </div>
  </div>
</div>
