import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Post } from "../models/posts";
import posts from "../../data/posts.json";

@Injectable({
  providedIn: "root",
})
export class BlogService {
  allTags: string[] = [];
  allCats: string[] = [];

  constructor(private http: HttpClient) {
    this.GetCategories();
    this.GetTags();
  }

  GetPosts(
    categories: string[],
    tag: string[],
    take: number = 3,
    start: number = 0
  ) {
    const sortedPosts = posts.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    // tslint:disable-next-line: max-line-length
    const sortedCategories =
      categories === undefined || categories.length === 0
        ? sortedPosts
        : sortedPosts.filter((w) =>
            categories.every((s) => w.category.includes(s))
          );
    // tslint:disable-next-line: max-line-length
    const tags =
      tag === undefined || tag.length === 0
        ? sortedCategories
        : sortedCategories.filter((w) => tag.every((f) => w.tags.includes(f)));
    return tags.slice(start, take);
  }

  GetPost(fileName: string) {
    return this.http.get("assets/blogs/" + fileName, { responseType: "text" });
  }

  GetPostByID(blogId: string): Post {
    return posts.find((w) => w.id === blogId);
  }

  GetCategories() {
    posts.forEach((post) => {
      post.category.forEach((cat) => {
        if (!this.allCats.includes(cat)) {
          this.allCats.push(cat);
        }
      });
    });
  }

  GetTags() {
    posts.forEach((post) => {
      post.tags.forEach((tag) => {
        if (!this.allTags.includes(tag)) {
          this.allTags.push(tag);
        }
      });
    });
  }
}
