<div class="container background">
  <div class="row">
    <div class="col">
      <div class="text-center">
        <h2 class="m-3">{{ categories }} Gallery</h2>
        <hr class="mx-auto" />
      </div>
      <!-- <div *ngIf="typesVisible" class="p-1 ms-2">
        <app-checkbox-selector
          [checkList]="allTypes"
          (OnSelected)="UpdateTypeSelection($event)"
        ></app-checkbox-selector>
      </div> -->

      <div *ngIf="catsVisible && !categories" class="p-1 ms-2">
        <app-checkbox-selector
          [checkList]="allCats"
          (OnSelected)="UpdateCategorySelection($event)"
        ></app-checkbox-selector>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-md-12">
      <ngx-masonry [options]="{ columnWidth: 200, gutter: 15 }">
        <div
          ngxMasonryItem
          class="masonry-item"
          *ngFor="let item of filteredGalleryIems"
        >
          <app-gallery-item
            (itemClicked)="GalleryItemClicked($event)"
            [item]="item"
          ></app-gallery-item>
        </div>
      </ngx-masonry>
    </div>
  </div>
</div>
<app-gallery-full
  [GalleryItem]="selectedItem"
  (itemClosed)="GalleryClosed()"
></app-gallery-full>
