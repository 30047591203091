<div class="container">
  <div class="text-center text-white fade-image">
    <img
      [src]="image"
      class="hero-image"
      alt="Kerry Marine Engineering"
      title="Kerry Marine Engineering Ltd"
    />
    <div class="hero-bottom">
      <p class="display-6 fw-bold">{{ title }}</p>
    </div>
  </div>
</div>
