import { Component, Input, OnInit } from "@angular/core";
import { IContact, Contact } from "../../../models/IContact";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit {
  @Input() serviceType:
    | "Welding & Fabrication"
    | "Marine Engineering"
    | "In House Machine Shop"
    | "Onsite Engineering & Dry Docking"
    | "Subsea Engineering";
  contact: IContact;
  contactSent = false;
  contactFailed = false;
  contactForm: FormGroup;

  serviceSelected: string[] = [];
  loading: boolean = false;

  constructor(private fb: FormBuilder, private dataService: DataService) {
    this.contactForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.contact = new Contact();
  }

  createFormGroup() {
    return this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      desc: ["", [Validators.required]],
      client: ["ksnEeRw1w", [Validators.required]],
    });
  }

  formSetValue() {
    if (this.contact !== undefined) {
      this.contactForm.reset({
        name: "",
        email: "",
        phone: "",
        desc: "",
        client: "ksnEeRw1w",
      });
    }
  }

  SubmitForm() {
    this.loading = true;
    if (this.contactForm.valid) {
      this.contactForm
        .get("desc")
        .setValue(
          `Message description: ${
            this.contactForm.get("desc").value
          } Services type: ${this.serviceSelected}`
        );
      this.contact = Object.assign({}, this.contactForm.value);
      console.log("form sent", this.contactForm.value);

      this.dataService.SendContactForm(this.contact).subscribe(
        (response) => {
          this.contactSent = true;
          this.loading = false;
        },
        (error) => {
          this.contactFailed = true;
          console.error("Error: " + error.response);
          this.loading = false;
        }
      );
    }
  }
  cancelError() {
    this.contactFailed = false;
  }
  cancelClicked() {
    this.resetNewItemToOriginal();
  }

  resetNewItemToOriginal() {
    // set the temp to the orginal
    this.formSetValue();
    this.contactSent = false;
    this.contactFailed = false;
  }
}
