import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-shop',
  templateUrl: './machine-shop.component.html',
  styleUrls: ['./machine-shop.component.scss']
})
export class MachineShopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
