<div class="backgroundMed">
  <div class="container pt-3">
    <div class="row justify-content-center text-white">
      <div class="media-container-column col-lg-8 text-center">
        <div>
          <h2 class="align-center pb-3 display-2">Contact us</h2>
          <h3 class="align-center mbr-light pb-3 display-5">
            Let us know what you are looking for and we can let you know how we
            can help
          </h3>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="media-container-column col-lg-8">
        <form [formGroup]="contactForm" (ngSubmit)="SubmitForm()">
          <div class="backgroundLight p-3 m-3">
            <div class="row row-sm-offset">
              <div class="col-md-4 multi-horizontal">
                <div class="md-form mt-0 mb-0">
                  <input
                    type="text"
                    class="form-control"
                    mdbInput
                    name="name"
                    id="forName"
                    formControlName="name"
                  />
                  <label for="forName" title="your full name would be nice"
                    >name</label
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form mt-0 mb-0">
                  <input
                    type="email"
                    class="form-control"
                    mdbInput
                    name="email"
                    id="formEmail"
                    formControlName="email"
                  />
                  <label for="formEmail" title="enter your email address"
                    >email address</label
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form mt-0 mb-0">
                  <input
                    type="tel"
                    class="form-control"
                    mdbInput
                    name="phone"
                    id="forPhone"
                    formControlName="phone"
                  />
                  <label for="forPhone" title="It's good to talk"
                    >telephone number</label
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="backgroundLight p-3 m-3">
            <div class="mb-2">
              <p>Which services you are interested in?</p>
              <div class="form-check form-check-inline">
                <input
                  (change)="serviceSelected = $event.value"
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="Welding & Fabrication"
                  [checked]="serviceType === 'Welding & Fabrication'"
                />
                <label class="form-check-label" for="inlineCheckbox1"
                  >Welding & Fabrication</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  (change)="serviceSelected = $event.value"
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  value="Marine Engineering"
                  [checked]="serviceType === 'Marine Engineering'"
                />
                <label class="form-check-label" for="inlineCheckbox2"
                  >Marine Engineering</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  (change)="serviceSelected = $event.value"
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                  value="In House Machine Shop"
                  [checked]="serviceType === 'In House Machine Shop'"
                />
                <label class="form-check-label" for="inlineCheckbox3"
                  >In House Machine Shop</label
                >
              </div>
            </div>
            <div>
              <div class="form-check form-check-inline">
                <input
                  (change)="serviceSelected = $event.value"
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox4"
                  value="Onsite Engineering & Dry Docking"
                  [checked]="serviceType === 'Onsite Engineering & Dry Docking'"
                />
                <label class="form-check-label" for="inlineCheckbox4"
                  >Onsite Engineering & Dry Docking</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  (change)="serviceSelected = $event.value"
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox5"
                  value="Subsea Engineering"
                  [checked]="serviceType === 'Subsea Engineering'"
                />
                <label class="form-check-label" for="inlineCheckbox5"
                  >Subsea Engineering</label
                >
              </div>
                       </div>
          </div> -->

          <div class="backgroundLight p-3 m-3">
            <div class="row row-sm-offset">
              <div class="col-sm-12">
                <div class="md-form mt-0 mb-1">
                  <textarea
                    type="text"
                    class="md-textarea form-control"
                    mdbInput
                    rows="7"
                    id="forDesc"
                    formControlName="desc"
                  ></textarea>
                  <label
                    for="forDesc"
                    title="As much information as you can would be good"
                    >Please give a brief outline of your requirements</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="m-2 ml-3 mb-5">
            <span class="input-group-btn"
              ><a
                type="submit"
                (click)="SubmitForm()"
                class="btn stylish-color-dark btn-form"
                >Send</a
              ></span
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-modal-popup
  [showPopup]="contactSent"
  [title]="'Contact Form Sent'"
  [description]="'Thank you - We will be in touch shortly'"
  [btnCancelText]="'Cancel'"
  (cancelClicked)="cancelClicked()"
></app-modal-popup>
<app-modal-popup
  [showPopup]="contactFailed"
  [title]="'Error sending email form'"
  [description]="
    'Please try again, if the problem persists please send your enquiry directly to karl@kerrymarine.co.uk'
  "
  [btnCancelText]="'Cancel'"
  (cancelClicked)="cancelError()"
></app-modal-popup>
