export interface IElement {
  type: typeEnum;
  p1: Point;
}

export class SvgPath {
  constructor(
    public type: typeEnum,
    public points: Point[]) {}
}

export class Point {
  constructor(
    public x: number,
    public y: number,
    public xl= 0,
    public yl= 0
    ) {}
}

export class MoveTo  {
  constructor(
  type: typeEnum,
  p1: Point) {}
}

export class LineTo {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point) {}
}

export class HorizontalLineTo {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point) {}
}

export class VerticalLineTo {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point) {}
}

export class CurveTo {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point,
    p3: Point) {}
}

export class QuadraticBézierCurve {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point) {}
}

export class SmoothQuadraticBézierCurve {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point) {}
}

export class EllipticalArc {
  constructor(
    type: typeEnum,
    p1: Point,
    p2: Point) {}
}


export enum typeEnum {
M, // moveto,
L, // lineto
H, // horizontal lineto
V, // vertical lineto
C, // curveto
S, // smooth curveto
Q, // quadratic Bézier curve
T, // smooth quadratic Bézier curveto
A, // elliptical Arc
Z, // closepath
m, // moveto,
l, // lineto
h, // horizontal lineto
v, // vertical lineto
c, // curveto
s, // smooth curveto
q, // quadratic Bézier curve
t, // smooth quadratic Bézier curveto
a, // elliptical Arc
z, // closepath
}

// M = moveto
// L = lineto
// H = horizontal lineto
// V = vertical lineto
// C = curveto
// S = smooth curveto
// Q = quadratic Bézier curve
// T = smooth quadratic Bézier curveto
// A = elliptical Arc
// Z = closepath
