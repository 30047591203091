import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { GdprComponent } from "./pages/gdpr/gdpr.component";
import { Iso9001Component } from "./pages/iso9001/iso9001.component";
import { WeldingComponent } from "./pages/Services/welding/welding.component";
import { MarineEngineeringComponent } from "./pages/Services/marine-engineering/marine-engineering.component";
import { MachineShopComponent } from "./pages/Services/machine-shop/machine-shop.component";
import { DryDockingComponent } from "./pages/Services/dry-docking/dry-docking.component";
import { SubseaEngineeringComponent } from "./pages/Services/subsea-engineering/subsea-engineering.component";
import { DredgePipesComponent } from "./pages/Services/dredge-pipes/dredge-pipes.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogFullPostComponent } from "./pages/blog-full-post/blog-full-post.component";
import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { GalleryPageComponent } from "./pages/gallery-page/gallery-page.component";
import { ReviewsComponent } from "./pages/reviews/reviews.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  relativeLinkResolution: 'legacy',
  onSameUrlNavigation: 'reload',
  enableTracing: true
};

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "terms", component: TermsComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "gdpr", component: GdprComponent },
  { path: "iso9001", component: Iso9001Component },
  { path: "welding-and-fabrication", component: WeldingComponent },
  { path: "marine-engineering", component: MarineEngineeringComponent },
  { path: "in-house-machine-shop", component: MachineShopComponent },
  {
    path: "onsite-engineering-and-dry-docking",
    component: DryDockingComponent,
  },
  { path: "subsea-engineering", component: SubseaEngineeringComponent },
  { path: "dredge-pipes", component: DredgePipesComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:blogId", component: BlogFullPostComponent },
  { path: "contact", component: ContactPageComponent },
  { path: "gallery", component: GalleryPageComponent },
  { path: "reviews", component: ReviewsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
