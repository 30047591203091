<div class="container page-content">
  <div class="m-5">
    <div class="row">
      <div class="col text-center">
        <h1>Kerry Marine Engineering Ltd.</h1>
      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        <h1>STANDARD TERMS AND CONDITIONS</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12 wow fadeInUp">
        <h2>1.	Definitions</h2>
        <ul>
          <li>1.1.	In the Contract the following definitions shall apply:</li>
          <li><b>“Business Day”</b> means a day other than a Saturday, Sunday or public holiday in England, when banks in London are open for business.</li>
          <li><b>“Charges”</b> means the charges payable by the Client for the supply of Services in accordance with these Conditions.</li>
          <li><b>“Company”</b> means Kerry Marine Engineering Limited, registered in England and Wales with company number 12654777.</li>
          <li><b>“Conditions”</b> means these terms and conditions as amended from time to time in accordance with Clause 17.</li>
          <li><b>“Contract”</b> means the contract between the Client and Company for the supply of Services in accordance with these Conditions together with the Quotation, if applicable, and any other documentation as agreed between the Parties.</li>
          <li><b>“Client”</b> means the person, firm or company requesting a quotation or placing an Order for Services with the Company.</li>
          <li><b>“Goods”</b> means any materials or products supplied by the Company to the client separately or in order to carry out the Services.</li>
          <li><b>“Order”</b> means the Client’s written order for Services which specifies what services shall be provided by the Company to the Client.</li>
          <li><b>“Quotation”</b> means the written quotation for the provision of Services to be supplied by the Company to the Client.</li>
          <li><b>“Services”</b> means the services, including any Goods, supplied by the Company to the Client as set out in the Order and/or Quotation.</li>
          <li><b>“Site”</b> refers to the agreed place at which the Services are to be carried out.</li>
        </ul>
      </div>

      <div class="col-12 wow fadeInUp">
        <ul>
          <li>1.2.	In the Contract the following rules of interpretation shall apply:</li>
          <ul>
            <li>i.	References to the “Company” and/or the “Client” may be collectively referred to as the “Parties” or individually as a “Party”.</li>
            <li>iii.	A person includes a natural person, corporate or unincorporated body.</li>
            <li>ii.	Unless the context otherwise requires, words in the singular shall include the plural and the plural shall include the singular.</li>
            <li>v.	Reference to “writing” or “written” shall include fax and email.</li>
            <li>iv.	Any reference to a statute or statutory provision is a reference to it as amended to re-enacted and shall include all subordinate legislation made from time to time under that statute or statutory provision.</li>
          </ul>
        </ul>
      </div>

          <div class="col-12 wow fadeInUp">
          <h2>
            2.	Basis of Contract
          </h2>
          <ul>
          <li>2.1	The Client shall submit to the Company an Order in a form agreed between the Parties, the Order shall contain all the necessary information and specifications of the Services which the Company requires.</li>
          <li>2.2	The Order constitutes an offer by the Client to engage the Company in the provision of Services in accordance with these Conditions, and if applicable, the Quotation.</li>
          <li>2.3	Written acceptance of the Order by the Company, or commencement of the Services by the Company on a planned or emergency call out basis, confirms acceptance by the Company of these Conditions, and the point at which the Contract shall come into existence (the “Commencement Date”).</li>
          <li>2.4	Any Quotation provided to the Client by the Company shall not constitute an offer and shall be in accordance with Clause 3.</li>
          <li>2.5	All Services sold to the Client by the Company are subject to the Company’s Conditions and shall take precedence over any terms and conditions referred to on any Client documentation.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
          <h2>
            3.	Quotation
          </h2>
          <ul>
            <li>3.1	The Company provides to the Client a Quotation for the provision of Services. The prices, quantities and Lead-time stated in any Quotations by the Company are a best commercial estimate based on the information available at the time unless otherwise stated.</li>
            <li>3.2	All Quotations are valid for a period of 30 days from the date of the Quotation unless otherwise stated. Thereafter the Quotation is subject to re-confirmation or adjustment by the Company.</li>
            <li>3.3	Unless otherwise stated, any Quotation is based on the Services being carried out during normal working hours (8:00am to 16:30pm) Monday to Friday.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
          <h2>
            4.	Supply of Services
          </h2>
          <ul>
            <li>4.1	The Company shall supply the Services to the Client in accordance with the specifications outlined in the Order in all material aspects.</li>
            <li>4.2	The Company shall use all reasonable endeavours to meet any performance dates specified in the Order (the “Lead-time”), any such dates shall be estimates only and time shall not be of the essence for performance of the Services.  If completion of the Services within the Lead-time is not achievable the Client will be informed as soon as practically possible and a revised Lead-time will be given by the Company.</li>
            <li>4.3	The Company reserves the right to amend the Order specifications if necessary, to comply with any applicable law or regulatory requirement, or if the amendment will not materially affect the nature or quality of the Services. And the Company shall notify the Client in any such event.</li>
            <li>4.4	The Company warrants to the Client that the Services shall be provided using reasonable care and skill.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
          <h2>
            5.	Charges
          </h2>
          <ul>
            <li>5.1	The Charges for the Services shall be calculated on a time and materials basis and shall be calculated in accordance with the Company’s daily fee rates as set out in its current price list at the date of the Contract which is displayed on the Quotation and can be provided upon request.</li>
            <li>5.2	The Company’s daily fee rates for each individual are calculated on the basis of normal working hours (8:00am to 16:30pm) Monday to Friday.</li>
            <li>5.3	The Company shall be entitled to charge the following additional costs (“Additional Costs”):</li>
            <ul>
              <li>i.	any increases in labour and/or material costs arising after the date of the Quotation may be recovered from the Client unless this is expressly excluded in the Quotation;</li>
              <li>ii.	any increase in the price for Goods which are required as part of the provision of Services where the Goods are, or become, subject to import/export tariffs as a result of the United Kingdom formally exiting the European Union or other regulatory change in relation to import/export tariffs;</li>
              <li>iii.	any variations to the Services or additional works requested by the Client after the Commencement Date which are not included in the initial Quotation shall be charged on a time and material basis, unless subject to a separate Quotation that is accepted by the Client;</li>
              <li>iv.	an overtime rate of for each part day or any time worked by individuals who are engaged on the Services outside the hours referred to in Clause 5.2, as agreed between the Parties prior to the Commencement Date;</li>
              <li>v.	any expenses reasonably incurred by the individuals whom the Company engages in connection with the Services including travelling expenses, hotel costs, subsistence and any associated expenses;</li>
              <li>vi.	the cost of services provided by third parties required by the Company for the performance of the Services; and</li>
              <li>vii.	the cost of any materials or Goods required to complete the Services.</li>
            </ul>
            <li>5.4	The Company reserves the right to increase the Charges on an annual basis with effect from each anniversary of the Commencement Date in line with the percentage increased in the Retail Prices Index in the preceding 12-month period and the first such increase shall take effect on the first anniversary of the Commencement Date and shall be based on the latest available figure for the percentage increase in the Retail Prices Index.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              6.	Payment
            </h2>
          <ul>
            <li>6.1	The Company shall invoice the Client either:</li>
            <ul>
              <li>i.	Upon completion of the Services, where the Services have a Lead-time of 30 days or less; or</li>
              <li>ii.	 On a monthly basis in arrears followed by a final invoice upon completion of the Services, where Services have a Lead-time of more than 30 days; or</li>
              <li>iii.	As otherwise agreed in writing between the Parties prior to the Services commencing.</li>
            </ul>
            <li>6.2	 The Client must pay each invoice submitted by the Company:</li>
            <ul>
              <li>i.	within 30 Business Days from the date of the invoice, unless otherwise agreed in writing between the Parties;</li>
              <li>ii.	in full and in cleared funds to a bank account nominated in writing by the Company; and</li>
              <li>iii.	time for payment shall be of the essence of the Contract.</li>
            </ul>
            <li>6.3	If any amount of an invoice is disputed, the Client shall inform the Company of the grounds for the dispute within 5 Business Days from the date of the invoice. During the period of resolution, the Client shall pay to the Company the value of the invoice less the disputed amount in accordance with these Conditions.</li>
            <li>6.4	All amounts payable by the Client under the Contract are exclusive of amounts in respect of value added tax chargeable from time to time (“VAT”). Where any taxable supply for VAT purposes is made under the Contract by the Company to the Client, the Client shall, on receipt of a valid VAT invoice from the Company, pay to the Company such additional amounts in respect of VAT as are chargeable on the supply of Services at the same time as payment is due for the supply of Services.</li>
            <li>6.5	If the Client fails to make a payment due to the Company under the Contract by the due date, where the Client has not issued a dispute in accordance with Clause 6.3 then, without limiting the Company’s remedies under Clause 8 (Termination Clause), the Client shall pay interest on the overdue sum from the due date until payment of the overdue sum, whether before or after judgement, at a rate of 4% above the Bank of England’s base rate from time to time.</li>
            <li>6.6	All amounts due under the Contract shall be paid in full without any set-off, counterclaim, deduction or withholding, other than any deduction or withholding of tax as required by law.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              7.	Client’s Obligations
            </h2>
            <ul>
            <li>7.1	In addition to any other obligations set out in these Conditions the Client shall:</li>
            <ul>
              <li>i.	ensure that the information provided in the Order is complete and accurate;</li>
              <li>ii.	co-operate with the Company in all matters relating to the Services;</li>
              <li>iii.	provide the Company, its employees, agents, consultants and subcontractors, with access to the Client’s premises and other facilities reasonably required by the Company to perform the Services;</li>
              <li>iv.	not use the Company’s personnel for publicity, endorsement or to comment on any matter related to the Contract other than for the purpose of the Contract;</li>
              <li>v.	inform the Company, its employees, agents, consultants and subcontractors, any safety measures put in place regarding contact with asbestos and comply with any other requirements in accordance with the Control of Asbestos at Work Regulations 2012, as amended from time to time, and any other codes of practice;</li>
              <li>vi.	prepare the Site or vessel (as applicable) for the supply of the Services;</li>
              <li>vii.	obtain and maintain all necessary licences, permissions and consents which may be required for the Services before the date on which the Services are to start; and</li>
              <li>viii.	keep all materials, equipment, documents and other property of the Company which is at the Client’s premises (the “Company Materials”) in safe custody at its own risk until returned to the Company and not dispute of or use the Company’s Materials other than in accordance with the Company’s written instructions or authorisations.</li>
            </ul>
            <li>7.2	If the Company’s performance of any of its obligations under the Contract is prevented or delayed by any act or omission by the Client or failure by the Client to perform any relevant obligation (“Client Default”) then:</li>
            <ul>
              <li>i.	without limiting or affecting any other right or remedy available to it, the Company shall have the right to suspend performance of the Services until the Customer remedies the Customer Default, and to rely on the Customer Default to relieve it from the performance of any of its obligations in each case to the extent the Customer Default prevents or delays the Company’s performance of any of its obligations;</li>
              <li>ii.	the Company shall not be liable for any costs or losses sustained or incurred by the Client arising directly or indirectly from the Company’s failure or delay to perform any of its obligations as set out in the Conditions; and</li>
              <li>iii.	the Client shall reimburse the Company on written demand for any costs or losses sustained or incurred by the Company arising directly or indirectly from the Client Default.</li>
            </ul>
            <li>7.3	The Company shall not be held responsible for any loss or damage arising out of delay due to any cause beyond the Company’s control.</li>
            </ul>
          </div>

          <div class="col-12 wow fadeInUp">
          <h2>
            8.	Termination
          </h2>
          <ul>
            <li>8.1	In the event the Client wishes to terminate the Contract for any reason, they must notify the Company in writing as soon as practically possible. In such instances the Company is entitled to invoice the Client for any losses, including but not limited to materials, labour, sub-contractor charges and expenses already incurred by the Company.</li>
            <li>8.2	The Company may terminate the Contract with immediate effect by giving notice to the Client if the Client fails to pay any amount due under the Contract on the due date for payment and if the Client fails to remedy this within 7 Business Days of the due date for payment.</li>
            <li>8.3	Without affecting any other right or remedy available to it either Party may terminate the Contract with immediate effect by giving notice to the other Party if one of the following events occurs:</li>
            <ul>
              <li>i.	the other Party commits a material breach of any term of the Contract and fails to remedy that breach within 7 Business Days of that Party being notified in writing to do so;</li>
            </ul>
            <li>8.4	The Company may suspend the supply of Services under the Contract or any other contract between the Company and Client if the Client fails to pay any amount due under the Contract on the due date for payment, the Client becomes subject to any of the events listed in Clause 8.3, or the Company reasonably believes that the Client is about to become subject to any of them.</li>
            <li>8.5	On termination of the Contract:</li>
            <ul>
              <li>i.	The Client shall immediately pay to the Company all of the Company’s outstanding unpaid invoices and interest and, in respect of the Services supplied but for which no invoice has been submitted, the Company shall submit an invoice, which shall be payable by the Customer immediately on receipt; and</li>
              <li>ii.	The Client shall return all of the Company Materials and any Goods which have not been fully paid for. If the Client fails to do so, then the Company may enter the Client’s premises and take possession of them.</li>
            </ul>
            <li>8.6	Termination of the Contract shall not affect any rights, remedies, obligations or liabilities of the Parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination.</li>
            <li>8.7	Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination of the Contract shall remain in full force and effect.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
          <h2>
            9.	Risk of Loss, Damage and Retention of Title
          </h2>
          <ul>
            <li>9.1	Without prejudice to the Client’s statutory rights, the Company shall pass to the Client the benefit of any guarantees the Company has received in respect of materials supplied by the Company and undertakes to repair or, replace free of charge, any materials or Goods found to be defective.</li>
            <li>9.2	Complaints with regard to defects must be received by the Company as soon as possible after they have been observed and no later than 5 days from completion of the Services.</li>
            <li>9.2	The risk of loss and damage to the Goods shall pass to the Client immediately upon delivery to the Client or Site.</li>
            <li>9.3	The Company shall take reasonable steps to ensure the protection from loss, damage or destruction during Services carried out for the Client.</li>
            <li>9.4	Whilst the Company carries out the Services, it may be necessary to leave tools or equipment on site. In such instances the Client will be informed and should therefore ensure all equipment is covered under the relevant insurance held by the Client.</li>
            <li>9.5	All Goods shall remain the property of the Company until full payment for the Services has been received, when full title will pass to the Client.</li>
            <li>9.6	The Company accepts no responsibility for any drawing, design or specification not prepared by them.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              10.	Limitation of Liability
            </h2>
          <ul>
            <li>10.1	References to liability in this Clause 10 include every kind of liability arising under or in connection with this Contract including but not limited to liability in contract, tort (including negligence), misrepresentation, restitution or otherwise.</li>
            <li>10.2	Nothing in this Contract limited any liability which cannot legally be limited, including but not limited to, liability for death or personal injury caused by negligence.</li>
            <li>10.3	The Company shall not be liable for any claim arising out of the performance, non-performance, delay in delivery of or defect in the Goods, including but not limited to, any indirect or consequential loss (including loss of provide and/or revenue) or damage howsoever arising from or in connection with the Services supplied.</li>
            <li>10.4	The Company shall not be liable for any wear and tear, loss or damage, direct or indirect, not for extra work entailed due to the apparatus being put into operation by the Client, or by the Company, its servants or agents at the Client’s request before it is handed over for beneficial use.</li>
            <li>10.5	The repair or replacement of any faulty work or material shall only be carried out by the Company, its servants or agents, otherwise the Company’s warranties as to repair or replacement shall not apply.</li>
            <li>10.6	Any liability of the Company shall in any event be limited to the total price of the Services carried out.</li>
            <li>10.7	Nothing in this Clause 10 shall limit the Client’s payment obligations under the Contract.</li>
            <li>10.8	The Client shall fully indemnify the Company against any liability to third parties arising out of the Clients use of the Goods.</li>
            <li>10.9	The Company will take reasonable care but accepts no liability for damage to fixtures or fittings which have to be moved by the Company in order to carry out the required Services.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              11.	Warranty
            </h2>
          <p>
            The Company warrants that it has the right to provide Goods.  The Goods are provided on an “as-is” basis without warranty of any kind, express or implied, oral or written including, without limitation, the implied conditions of merchantable quality, fitness for purpose and description, all of which are specifically and un-reservedly excluded. The Company does not warrant that the Goods supplied are error-free, accurate or complete.
          </p>
          </div>


          <div class="col-12 wow fadeInUp">
            <h2>
              12.	Assignment
            </h2>
          <ul>
            <li>12.1	The Company may at any time assign, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights and obligations under the Contract;</li>
            <li>12.2	The Client shall not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any of its rights and obligations under the Contract without prior written consent of the Company.</li>
          </ul>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              13.	Confidentiality
            </h2>
          <ul>
            <li>13.1	Each Party shall undertake that it shall not at any time during the Contract, and for a period of two years after termination or expiry of the Contract, disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other Party, except where prior written agreement by the other Party has been given or as permitted by Clause 13.2.</li>
            <li>13.2	Each party may disclose the other Party’s confidential information:</li>
            <ul>
              <li>i.	     To its employees, officers, representatives, contractors, subcontractors or advisors who need to know such information for the purpose of carrying out the Party’s obligations under the Contract. Each Party shall ensure that its employees. Officers, representatives, contractors, subcontractors or advisers to whom it disclosed the other Party’s confidential information comply with this Clause 13;</li>
              <li>ii.	      As may be required by law, a court of competent jurisdiction or governmental or regulatory authority.</li>
            </ul>
            <li>13.3	Neither Party shall use the other Party’s confidential information for any purpose other than to perform its obligations under the Contract.</li>
          </ul>
          </div>


          <div class="col-12 wow fadeInUp">
            <h2>
              14.	Intellectual Property Rights
            </h2>
          <ul>
            <li>14.1	All Intellectual Property Rights in or arising out of or in connection with the Services (other than Intellectual Property Rights in any materials provided by the Client) shall be owed by the Company.</li>
            <li>14.2	Any designs/drawings provided by the Company remains the Company’s copyright.</li>
            <li>14.3	All Company materials are the exclusive property of the Company.</li>
          </ul>
          </div>


          <div class="col-12 wow fadeInUp">
            <h2>
              15.	Force Majeure
            </h2>
          <p>
            Neither Party shall be in breach of the Contract nor liable for delay in performing, or failure to perform, any of its obligations under the Contract if such delay or failure result from events, circumstances or causes beyond its reasonable control of that Party. Such causes include, but are not limited to: power failure, internet service failure, industrial action, civil unrest, fire, flood, storms, earthquakes, acts of terrorism, acts of war, pandemic, epidemic, governmental action or any other event that is beyond the control of the Party in question.
          </p>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              16.	Notice
            </h2>
          <p>
            Any notice given to a Party in connection with the Contract shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its principal place of business or sent by fax or email.
          </p>
          </div>

          <div class="col-12 wow fadeInUp">
            <h2>
              17.	Variation
            </h2>
          <p>
            Except as set out in these Conditions, no variation of the Contract shall be effective unless it is in writing and signed by the Parties or their authorised representatives.
          </p>
          </div>


          <div class="col-12 wow fadeInUp">
            <h2>
              18.	General
            </h2>
          <ul>
            <li>18.1	Any obligation on a party to not do something includes an obligation not to allow that thing to be done.</li>
            <li>18.2	The Contract constitutes the entire agreement between the Parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.</li>
            <li>18.3	Each party acknowledges that in entering the Contract it does not rely on, and shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in the Contract. Each Party agrees it shall have no claim for innocent or negligent misrepresentation based on any statement in the Contract.</li>
            <li>18.4	Without prejudice the Company will maintain adequate Public Liability Insurance cover.</li>
            <li>18.5	Unless it expressly states otherwise, the Contract does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract.</li>
            <li>18.6	The Company shall be entitled to sub-contract all or any part of the Services, unless otherwise agreed between the Parties.</li>
            <li>18.7	The Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation shall be governed by, and construed in accordance with the law of England and Wales.</li>
            <li>18.8	Each Party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with the Contract or its subject matter or formation.</li>
          </ul>

          </div>
     </div>
  </div>

</div>
