<div *ngIf="showImage" class="image-container" (click)="GalleryItemClosed()">
  <div class="overlay"></div>

  <div [@showImage] class="image">
    <img class="full-image image-fluid rounded" [src]="fullImageURL" />
    <span *ngIf="Item.title" class="item-title">{{ Item.title }}</span>
    <span *ngIf="Item.description" class="item-description">{{
      Item.description
    }}</span>
  </div>
</div>
