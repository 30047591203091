import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-big-carousel',
  templateUrl: './big-carousel.component.html',
  styleUrls: ['./big-carousel.component.scss']
})
export class BigCarouselComponent implements OnInit {

  @ViewChild('carousel', {static: true}) carousel: any;

  ngAfterViewInit() {
    this.carousel.el.nativeElement.dispatchEvent(new Event('mouseleave'));
  }

  constructor() { }

  ngOnInit(): void {
  }

}
