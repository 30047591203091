<div class="container fixed-top header-sticky">
  <div class="navbar-custom navbar-dark wow fadeInDown" data-wow-delay="0.2s">
    <!--Navbar-->
    <mdb-navbar
      SideClass="px-2 navbar navbar-expand-lg custom-toggler intro-fixed-nav"
      [containerInside]="false"
    >
      <!-- Navbar brand -->

      <navbar-brand>
        <div class="d-flex align-items-center">
          <img
            class="logo-bg d-none d-md-block"
            src="assets/images/cws-logo.svg"
            alt="Kerry Marine Logo"
          />
          <img
            class="logo-sm d-md-none"
            src="assets/images/cws-logo.svg"
            alt="Kerry Marine Logo"
          />
          <a
            [routerLink]="['/home']"
            class="logo-name no-wrap header-bg d-none d-md-block"
            >Kerry Marine Engineering Ltd</a
          >
          <a
            [routerLink]="['/home']"
            class="logo-name no-wrap header-sm d-md-none"
            >Kerry Marine Engineering Ltd</a
          >
        </div>
      </navbar-brand>

      <!-- Collapsible content -->
      <links>
        <!-- Links -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a
              class="nav-link waves-light text-white"
              [routerLink]="['.']"
              [fragment]="isHomePage ? 'ContactForm' : ''"
              [state]="{
                scroll: 'ContactForm'
              }"
              mdbWavesEffect
            >
              <span class="no-wrap"
                ><mdb-icon fas icon="envelope gold-kme"></mdb-icon>
                Contact</span
              >
            </a>
          </li>
          <li class="nav-item active">
            <a
              class="nav-link waves-light text-white"
              [routerLink]="['/reviews']"
              mdbWavesEffect
            >
              <span class="no-wrap"
                ><mdb-icon fas icon="comment gold-kme"></mdb-icon> Reviews</span
              >
            </a>
          </li>
          <li class="nav-item dropdown waves-light text-white">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <mdb-icon fas icon="wrench gold-kme"></mdb-icon> Services
            </a>
            <div
              class="dropdown-menu navbar-custom"
              aria-labelledby="navbarDropdown"
            >
              <a
                class="dropdown-item"
                [routerLink]="['/welding-and-fabrication']"
                >Welding & Fabrication</a
              >
              <a class="dropdown-item" [routerLink]="['/marine-engineering']"
                >Marine Engineering</a
              >
              <a class="dropdown-item" [routerLink]="['/in-house-machine-shop']"
                >In House Machine Shop</a
              >
              <a
                class="dropdown-item"
                [routerLink]="['/onsite-engineering-and-dry-docking']"
                >Onsite Engineering and Dry Docking</a
              >
              <!-- <a class="dropdown-item" [routerLink]="[/subsea-engineering"
                >Subsea Engineering</a
              > -->
              <!-- <a class="dropdown-item" [routerLink]="[/dredge-pipes">Dredge Pipes </a> -->
            </div>
          </li>
          <li class="nav-item">
            <a
              class="nav-link waves-light text-white"
              [routerLink]="'/gallery'"
              mdbWavesEffect
            >
              <span class="no-wrap"
                ><mdb-icon fas icon="image gold-kme"></mdb-icon> Gallery
              </span>
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link waves-light text-white"
              [routerLink]="'.'"
              [fragment]="isHomePage ? 'WhoAreWe' : ''"
              [state]="{
                scroll: 'WhoAreWe'
              }"
              mdbWavesEffect
            >
              <span class="no-wrap"
                ><mdb-icon fas icon="users gold-kme"></mdb-icon> Who are we?
              </span>
            </a>
          </li>

          <!-- Dropdown -->
          <!-- <li class="nav-item dropdown" dropdown>
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light cws-blue" mdbWavesEffect>
              <mdb-icon fas icon="lock  text-white"></mdb-icon> Policies<span class="caret"></span>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
              <div class="dropdown-item">
                <mdb-icon fas icon="ban"></mdb-icon>&nbsp;&nbsp;<a [routerLink]="'privacy'">Privacy</a>
              </div>
              <div class="dropdown-item">
                <mdb-icon fas icon="shield-alt"></mdb-icon>&nbsp;&nbsp;<a [routerLink]="'gdpr'">GDPR</a>
              </div>
              <div class="dropdown-item">
                <mdb-icon fas icon="file-signature"></mdb-icon>&nbsp;&nbsp;<a  [routerLink]="'terms'">Terms & Conditions</a>
              </div>
            </div>
          </li> -->
        </ul>
        <!-- Links -->
      </links>
      <!-- Collapsible content -->
    </mdb-navbar>
    <!--/.Navbar-->
  </div>
</div>
