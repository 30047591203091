import { Component, OnInit, Input } from '@angular/core';
import { Photo } from 'src/app/models/Photo';

@Component({
  selector: 'app-photo-banner',
  templateUrl: './photo-banner.component.html',
  styleUrls: ['./photo-banner.component.scss']
})
export class PhotoBannerComponent implements OnInit {

  @Input()
  photos: Photo[];

   constructor() { }

  ngOnInit(): void {
  }

}
