<div class="navbar-dark custom-color">
  <div class="container">
    <div class="p-5 bk-logo text-center">
      <div *ngIf="!!opening">
        <p class="lead tag-line display-5">{{ title }}</p>
        <span class="fa-stack fa-7x">
          <i class="fas fa-calendar fa-stack-2x blue-kme-300"></i>
          <span class="text-white fa-stack-1x fa-inverse fa-xs pt-5">24/7</span>
        </span>
        <p class="display-5 py-3 tag-line">{{ message }}</p>
      </div>

      <div *ngIf="!opening" class="text-center text-white p-5">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
      </div>

      <div *ngIf="btnShow2" class="row text-center p-2">
        <div class="col-sm-12 col-md-6 pb-2">
          <a [class]="btnClass" [routerLink]="['./']" [fragment]="'ContactForm'">{{ contact }}</a>
        </div>
        <div class="col-sm-12 col-md-6">
          <a [class]="btnClass2" [routerLink]="['./']" [fragment]="'ContactForm'">{{ contact2 }}</a>
        </div>
      </div>

      <div *ngIf="!btnShow2" class="row text-center p-2">
        <div class="col-sm-12 pb-2">
          <a [class]="btnClass" [routerLink]="['./']" [fragment]="'ContactForm'">{{ contact }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
