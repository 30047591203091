import { Component, OnInit } from "@angular/core";
import { btnTypeEnum } from "src/app/enums/enums";
import { DataService } from "src/app/services/data.service";
import { IFeature } from "src/app/models/IFeature";
import { Photo } from "src/app/models/Photo";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  features1: IFeature[] = [];
  features2: IFeature[] = [];
  features3: IFeature[] = [];
  photos: Photo[] = [];

  goTo;

  btnTypeEnum = btnTypeEnum;

  constructor(private dataservice: DataService, private router: Router) {
    this.goTo = this.router.getCurrentNavigation()?.extras?.state?.scroll;
    dataservice.GetFeatures().subscribe(
      (data) => {
        this.features1 = data.filter((w) => w.set === 1);
        this.features2 = data.filter((w) => w.set === 2);
        this.features3 = data.filter((w) => w.set === 3);
      },
      (err) => {
        console.log(err);
      }
    );
    dataservice.GetPhotos().subscribe(
      (data) => {
        this.photos = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.goTo) {
      const element = document.getElementById(this.goTo);
      this.scrollToFragment(element);
    }
  }
  scrollToFragment(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }, 1000);
  }
}
