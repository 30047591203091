import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welding',
  templateUrl: './welding.component.html',
  styleUrls: ['./welding.component.scss']
})
export class WeldingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
