import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dredge-pipes",
  templateUrl: "./dredge-pipes.component.html",
  styleUrls: ["./dredge-pipes.component.scss"],
})
export class DredgePipesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
