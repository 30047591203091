import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IGallery } from "src/app/models/IGallery";

@Component({
  selector: "app-gallery-full",
  templateUrl: "./gallery-full.component.html",
  styleUrls: ["./gallery-full.component.scss"],
  animations: [
    trigger("showImage", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("1s", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate(".5s", style({ opacity: 0 }))]),
    ]),
  ],
})
export class GalleryFullComponent implements OnInit {
  showImage: boolean;

  Item: IGallery;
  @Input() set GalleryItem(value: IGallery) {
    this.Item = value;
    this.showImage = value !== undefined;
    if (this.showImage) {
      this.fullImageURL = this.sanitizer.bypassSecurityTrustUrl(
        "assets/gallery/" + this.Item.imageURL
      );
    }
  }
  fullImageURL: any;

  @Output() itemClosed = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  GalleryItemClosed() {
    this.GalleryItem = undefined;
    this.itemClosed.emit();
  }
}
