<div class="card h-100 wow flipInX" data-wow-delay="delay">
  <div class="card-img-top">
    <img class="img-fluid w-100 image rounded-top" [src]="safeSrc" alt="{{ title }}" title="{{ title }}" />
  </div>
  <div class="card-body" [ngClass]="link ? 'pb-0' : ''">
    <h3>{{ title }}</h3>
    <p class="display-7 pre-wrap" [innerHTML]="bodytext"></p>
  </div>
  <div *ngIf="link" class="card-footer bg-white border-top-0">
    <a class="btn btn-link" [routerLink]="[link]">Learn more ></a>
  </div>
</div>