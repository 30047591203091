import { Component, Input, OnInit } from "@angular/core";
import serviceData from "./../../../data/serviceData.json";
import { trigger, transition, style, animate, query, stagger } from "@angular/animations";

@Component({
  selector: "app-service-template",
  templateUrl: "./service-template.component.html",
  styleUrls: ["./service-template.component.scss"],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0, transform: 'translateY(-15px)' }),
          stagger(100, [
            animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class ServiceTemplateComponent implements OnInit {
  @Input() page;
  service;

  constructor() {}

  get services() {
    return serviceData;
  }

  ngOnInit(): void {
    this.service = this.services.filter((t) => t.type == this.page);
    console.log("what page am i on", this.service);
  }
}
