import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-feature-card",
  templateUrl: "./feature-card.component.html",
  styleUrls: ["./feature-card.component.scss"],
})
export class FeatureCardComponent implements OnInit {
  @Input()
  iconCss: string;

  @Input()
  title: string;

  @Input()
  bodytext: string;

  @Input()
  delay: number;

  constructor() {}

  ngOnInit(): void {}
}
