<div>
  <div class="text-center p-5 bk-logo">
    <div class="container">
      <div class="text-center">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
      </div>
      <div class="row">
        <div *ngFor="let team of teams" [class]="class">
          <app-team
            (cardButtonClicked)="cardClicked(team.buttonRoute)"
            [name]="team.name"
            [position]="team.position"
            [src]="team.src"
            [cardTitle]="team.cardTitle"
            [cardText]="team.cardText"
            [buttonText]="team.buttonText"
            [paddingCSSclass]="'p-2'"
            [delay]="GetDelay(team.id)"
          >
          </app-team>
        </div>
      </div>
    </div>
  </div>
</div>
