<div class="p-5">
  <div class="container wow fadeInUp">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="lead fs-2 py-2">Latest News</h2>
        <p class="lead fs-2 py-3">
          All the latest news from the world of Kerry Marine Engineering Ltd
        </p>
      </div>
    </div>

    <div class="row">
      <div
        *ngFor="let post of posts"
        class="col-sm-4 wow flipInX"
        data-wow-delay="delay"
      >
        <div class="mini-post p-3 mb-3">
          <app-blog-post [post]="post"></app-blog-post>
        </div>
      </div>
    </div>
  </div>
</div>
