<div class="row">
  <div class="col-md-7 my-auto text-center mx-auto dryText p-4">
    <h3 class="pb-4">Dry Docking</h3>
    <p>
      We have a full understanding of the requirements<br />from all class
      approved repairs, so this will speed up<br />the time required out of the
      water, this will save time<br />and money for the client. We have
      completed around<br />300 refits from 5ton vessels to around 10,000ton.
    </p>
  </div>
  <div class="col-md-5 text-end">
    <img
      class="img-fluid"
      src="./assets/kerry-images/boat-scaff.jpg"
      alt="dry docking"
    />
  </div>
</div>
