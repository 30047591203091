<div class="text-center text-white">
  <img src="./assets/kerry-images/hero.jpg" class="hero-image" alt="Kerry Marine Engineering" title="Kerry Marine Engineering Ltd">
    <!--<div class="hero-top">
      <h1>Kerry Marine Engineering Ltd</h1>
    </div>-->
    <div class="hero-bottom">
      <h2>Portsmouth based ship repair excellence</h2>
      <h3>Find out more</h3>
      <a [routerLink]='"."' [fragment]="'AboutUs'"><i class="fas fa-chevron-down fa-2x text-white mb-3"></i></a>
    </div>
</div>
