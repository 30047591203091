<div class="d-flex flex-column flex-md-row mb-3">
  <div *ngFor="let item of checkList" class="col">
    <input
      type="checkbox"
      class="me-1"
      (change)="OnSelect(item)"
      [(ngModel)]="item.isSelected"
      ngDefaultControl
    />{{ item.name }}
  </div>
</div>
