<div class="row">
  <div class="col-md-6">
    <img
      class="img-fluid"
      src="./assets/kerry-images/cresent-beaune-3.jpg"
      alt="project management"
    />
  </div>
  <div class="col-10 col-md-6 my-auto mx-auto dryText p-4">
    <h3 class="py-4">Project Management</h3>
    <p>
      We can offer a full turn key operation from project management to full
      repair services.<br /><br />Call us now on 07307 100 254 or 07476 135 843
      to discuss your project requirements.
    </p>
  </div>
</div>
