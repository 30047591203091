<mdb-card class="card-deck wow flipInX text-center custom-color op-1 m-2 h-100" data-wow-delay="delay">
  <!--Card image-->
  <!-- <mdb-card-img [src]="src" [class]="paddingCSSclass"></mdb-card-img> -->
  <div class="card-img-top">
    <div class="row p-3">
      <div class="col-sm-4 mx-auto img-container">
        <img class="img-fluid" [src]="src" alt="{{cardTitle}}" title="{{cardTitle}}">
      </div>
    </div>
  </div>
  <!--Card content-->
  <mdb-card-body>

    <!--Title-->
    <mdb-card-title>
      <h4>{{cardTitle}}</h4>
    </mdb-card-title>

    <!--Text-->
    <mdb-card-text class="text-white">{{cardText}}</mdb-card-text>



    <div>
      <a (click)="onCardButtonClicked()" class="btn stylish-color-dark" *ngIf="showButton">{{buttonText}}</a>
    </div>
  </mdb-card-body>
  <mdb-card-footer class="border-top-0 pb-5">
    <h2 class="gold-kme">{{name}}</h2>
    <p>{{position}}</p>
  </mdb-card-footer>
</mdb-card>