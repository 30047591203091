import { Component, OnInit, ViewChild } from '@angular/core';
import { TimelineMax, random, gsap } from 'gsap';
import { MoveTo, CurveTo, typeEnum, Point, SvgPath } from 'src/app/models/ISvg';
import { stringify } from 'querystring';

@Component({
  selector: 'app-cws-logo',
  templateUrl: './cws-logo.component.html',
  styleUrls: ['./cws-logo.component.scss']
})

export class CwsLogoComponent implements OnInit {

  //  logo = new TimelineMax({ repeat: -1, repeatDelay: 4}).to(top, 3, 0);
  // logo = new TimelineMax( {
  //   onUpdate: this.setTop
  // });
  //  tween: any;

  stopped = false;

  top = '';
  topRnd = '';
  topPts: SvgPath[] = [];
  topPtsRnd: SvgPath[] = [];

  middle = '';
  middleRnd = '';
  middlePts: SvgPath[] = [];
  middlePtsRnd: SvgPath[] = [];

  bottom = '';
  bottomRnd = '';
  bottomPts: SvgPath[] = [];
  bottomPtsRnd: SvgPath[] = [];

  setTop() {

    this.setSvg(this.topPts, this.topPtsRnd, new SvgPath(typeEnum.M,
      [new Point(216, 100)]));

    this.setSvg(this.topPts, this.topPtsRnd, new SvgPath(typeEnum.C, [
      new Point(234, 67),
      new Point(253, 35),
      new Point(272, 3)
    ]));

    this.setSvg(this.topPts, this.topPtsRnd, new SvgPath(typeEnum.C, [
      new Point(468, 118, 150, -150),
      new Point(698, 169, 150, -150),
      new Point(1020, 104, 0, 150)     // point
    ]));

    this.setSvg(this.topPts, this.topPtsRnd, new SvgPath(typeEnum.C, [
      new Point(805, 240, 0, -150),
      new Point(462, 241, 0, -150),
      new Point(216, 100)
    ]));

    this.top = this.stringifySVG(this.topPts);
    this.topRnd = this.stringifySVG(this.topPtsRnd);

    // C 234 67 253 35 272 3
    // C 468 118 698 169 1020 104
    // C 805 240 462 241 216 100

    // this.tween = TweenMax.to(this.top)
    // this.logo.to(this.top, 3, {autoAlpha: 0});
  }

  setMiddle() {

    this.setSvg(this.middlePts, this.middlePtsRnd, new SvgPath(typeEnum.M,
      [new Point(116, 274)]));

    this.setSvg(this.middlePts, this.middlePtsRnd, new SvgPath(typeEnum.C, [
      new Point(116, 272),
      new Point(180, 161),
      new Point(181, 161)
    ]));

    this.setSvg(this.middlePts, this.middlePtsRnd, new SvgPath(typeEnum.C, [
      new Point(438, 327, 0, -50),
      new Point(704, 192, 0, -50),
      new Point(1068, 325, 0, 50)
    ]));

    this.setSvg(this.middlePts, this.middlePtsRnd, new SvgPath(typeEnum.C, [
      new Point(666, 298, 0, -50),
      new Point(410, 454, 0, -50),
      new Point(116, 274)
    ]));

    this.middle = this.stringifySVG(this.middlePts);
    this.middleRnd = this.stringifySVG(this.middlePtsRnd);

    // C 116 272 180 161 181 161
    // C 438 327 704 192 1068 325
    // C 666 298 410 454 116 274

   // this.tween = TweenMax.to(this.top)
  //  this.logo.to(this.top, 3, {autoAlpha: 0});
  }

  setBottom() {

    this.setSvg(this.bottomPts, this.bottomPtsRnd, new SvgPath(typeEnum.M,
      [new Point(5, 466)]));

    this.setSvg(this.bottomPts, this.bottomPtsRnd, new SvgPath(typeEnum.C, [
      new Point(5, 464),
      new Point(79, 336),
      new Point(80, 336)
    ]));

    this.setSvg(this.bottomPts, this.bottomPtsRnd, new SvgPath(typeEnum.C, [
      new Point(406, 540, -75, -75),
      new Point(863, 320, -75, -75),
      new Point(1113, 506, 0, 75)
    ]));

    this.setSvg(this.bottomPts, this.bottomPtsRnd, new SvgPath(typeEnum.C, [
      new Point(689, 439, 0, -75),
      new Point(481, 675, 0, -75),
      new Point(5, 466)
    ]));

    this.bottom = this.stringifySVG(this.bottomPts);
    this.bottomRnd = this.stringifySVG(this.bottomPtsRnd);

    // C 5 464 79 336 80 336
    // C 406 540 863 320 1113 506
    // C 689 439 481 675 5 466

   // this.tween = TweenMax.to(this.top)
  //  this.logo.to(this.top, 3, {autoAlpha: 0});
  }

  constructor() { }

  setSvg(arr: SvgPath[], animArr: SvgPath[], svg: SvgPath) {
    arr.push(svg);
    animArr.push(new SvgPath(svg.type, this.GenerateRnd(svg.points)));
  }

  GenerateRnd(pts: Point[]): Point[] {
    const retPts: Point[] = [];

    pts.forEach(pt => {
      retPts.push(
        new Point(this.getRnd(pt.x, pt.xl), this.getRnd(pt.y, pt.yl) )
      );
    });

    return retPts;
  }

  ngOnInit(): void {
    this.setTop();
    this.setMiddle();
    this.setBottom();
   // gsap.ticker.add(this.setTop);
  }

  getRnd(startingPt: number,  randomness: number): number {
    if (this.stopped) { return startingPt; }
    return startingPt + randomness; // (startingPt - randomness) * Math.random();
  }

  stringifySVG(svg: SvgPath[]): string {
    let svgString = '';

    svg.forEach(sPath => {
      svgString = svgString + typeEnum[sPath.type] + ' ';
      sPath.points.forEach(pnt => {
        svgString = svgString + this.stringifyPoint(pnt);
      });
    });

    svgString = svgString + typeEnum[typeEnum.Z];

    return svgString;
  }

  stringifyPoint(pnt: Point): string {
    return pnt.x.toString() + ',' + pnt.y.toString() + ' ';
  }


// Uppercase = Absolute, Lowercase = Relative
// M = moveto
// L = lineto
// H = horizontal lineto
// V = vertical lineto
// C = curveto
// S = smooth curveto
// Q = quadratic Bézier curve
// T = smooth quadratic Bézier curveto
// A = elliptical Arc
// Z = closepath

}
