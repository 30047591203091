import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import gallery from "./../../data/galleryItems.json";

@Injectable({
  providedIn: "root",
})
export class GalleryService {
  constructor(private http: HttpClient) {}

  GetGalleryItemsALL() {
    return gallery;
  }

  GetGallery(categories: string[]) {
    // tslint:disable-next-line: max-line-length
    const categorised =
      categories === undefined || categories.length === 0
        ? gallery
        : gallery.filter((w) => categories.some((s) => w.category.includes(s)));

    return categorised;
  }
}
