import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgxMasonryComponent } from "ngx-masonry";
import { CheckBoxItem } from "src/app/models/CheckBoxItem";
import { IGallery } from "src/app/models/IGallery";
import { GalleryService } from "src/app/services/gallery.service";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit {
  // ALL / Initial options
  @Input() categories: string;
  //  @Input() types: string[] = [];
  //  @Input() onlyPerfectFit: boolean;
  //  @Input() onlyIntu: boolean;

  // ALL Items as returned from initial call to GalleryService
  @Input() galleryIems: IGallery[] = [];
  @Input() filteredGalleryIems: IGallery[] = [];

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  allCats: CheckBoxItem[] = [];
  catsVisible: boolean;
  allTypes: CheckBoxItem[] = [];
  typesVisible: boolean;

  showFullImage: boolean;
  selectedItem: IGallery;

  constructor(private gallerService: GalleryService) {}

  ngOnInit(): void {
    this.SearchGallery();
    // this.ResetFilters();
    this.GetCategories();
  }

  UpdateSystem() {
    this.UpdateGallery();
  }

  SearchGallery() {
    // tslint:disable-next-line: max-line-length
    this.galleryIems = this.gallerService.GetGallery(
      this.allCats.filter((w) => w.isSelected).map((m) => m.name)
    );
    if (!!this.categories) {
      this.filteredGalleryIems = this.galleryIems.filter(
        (cat) => cat.category[0].toString() === this.categories
      );
    } else {
      this.filteredGalleryIems = this.galleryIems;
    }
  }

  ResetFilters(): void {
    this.filteredGalleryIems = this.galleryIems;
    //  this.UpdateGallery();
  }

  //  filterGallery()
  //  {
  //    this.filteredGalleryIems = this.galleryIems.filter(w => w.isIntu);
  //  }

  GetCategories() {
    this.galleryIems.forEach((item) => {
      item.category.forEach((cat) => {
        if (!this.allCats.some((w) => w.name === cat)) {
          this.allCats.push({ name: cat, isSelected: true });
        }
      });
    });
    console.log("cats", this.allCats);
    this.catsVisible = this.allCats.length > 1;
  }

  // GetTypes() {
  //   this.galleryIems.forEach((item) => {
  //     if (!this.allTypes.some((w) => w.name === item.type)) {
  //       this.allTypes.push({ name: item.type, isSelected: true });
  //     }
  //   });

  //   this.typesVisible = this.allTypes.length > 1;
  // }

  UpdateTypeSelection($event: CheckBoxItem[]) {
    this.allTypes = $event;
    this.UpdateGallery();
  }

  UpdateCategorySelection($event: CheckBoxItem[]) {
    this.allCats = $event;
    this.UpdateGallery();
  }

  UpdateGallery() {
    this.filteredGalleryIems = this.galleryIems.filter((w) =>
      this.allCats.some(
        (s) => s.isSelected && w.category.some((a) => a === s.name)
      )
    );
    this.masonry.layout();
  }

  GalleryItemClicked($event: IGallery) {
    this.selectedItem = $event;
  }

  GalleryClosed() {
    this.selectedItem = undefined;
  }
}
