import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Post } from "src/app/models/posts";
import { BlogService } from "src/app/services/blog.service";

@Component({
  selector: "app-blog-full-post",
  templateUrl: "./blog-full-post.component.html",
  styleUrls: ["./blog-full-post.component.scss"],
})
export class BlogFullPostComponent implements OnInit {
  blogId: string;
  html: string;
  post: Post;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((routeParams) => {
      this.blogId = routeParams.blogId;
      this.post = this.blogService.GetPostByID(this.blogId);

      this.blogService.GetPost(this.post.postHtml).subscribe(
        (postHtml) => {
          this.html = postHtml;
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
}
