<div class="navbar-dark custom-color p-5">
  <div class="container wow fadeInUp">
    <div class="text-center text-white">
      <h2>{{ title }}</h2>
      <p>{{ message }}</p>
    </div>
    <div class="row pt-3">
      <div
        *ngFor="let feature of features"
        class="col-12 col-md-4 text-center card-group p-3"
      >
        <app-feature-card
          *ngIf="feature.iconCss !== null"
          [iconCss]="feature.iconCss"
          [title]="feature.title"
          [bodytext]="feature.bodytext"
          [delay]="feature.id"
        ></app-feature-card>
        <app-feature-card-image
          *ngIf="feature.imgSrc !== null"
          [imgsrc]="feature.imgSrc"
          [title]="feature.title"
          [bodytext]="feature.bodytext"
          [delay]="feature.id"
          [link]="feature.link"
        ></app-feature-card-image>
      </div>
    </div>
  </div>
</div>
