<section class="wow fadeInUp" data-wow-delay="0.2s" id="footer">
  <div class="container-fluid custom-color footer-gold">
    <div class="container">
      <div class="row">
        <!-- <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="media-wrap">
            <div class="mt-5 logo">
              <img src="assets/images/cws-logo.svg" alt="Kerry Marine Logo" />
            </div>
          </div>
        </div> -->
        <div class="col-sm-6 col-md-3 col-lg-3">
          <h4 class="pt-2">Address</h4>
          <p class="mbr-text text-white">
            Kerry Marine Engineering Ltd<br />Unit 5 Bridge Industries&nbsp;<br />Fareham<br />PO16
            8SX
          </p>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <h4 class="pt-2">Contacts</h4>
          <p class="mbr-text">
            <mdb-icon fas icon="envelope"></mdb-icon>&nbsp;&nbsp;<a
              href="mailto:karl@kerrymarine.co.uk"
              >karl@kerrymarine.co.uk</a
            ><br />
            <mdb-icon fas icon="phone"></mdb-icon>&nbsp;&nbsp;Phone: Karl:
            <a href="tel:07370100254">07307 100254</a>
          </p>
          <p class="mbr-text">
            <mdb-icon fas icon="envelope"></mdb-icon>&nbsp;&nbsp;<a
              href="mailto:kane@kerrymarine.co.uk"
              >kane@kerrymarine.co.uk</a
            ><br />
            <mdb-icon fas icon="phone"></mdb-icon>&nbsp;&nbsp;Phone: Kane:
            <a href="tel:07476135843">07476 135843</a>
          </p>
          <p class="mbr-text">
            <span>Accounts</span><br />
            <mdb-icon fas icon="envelope"></mdb-icon>&nbsp;&nbsp;<a
              href="mailto:sam@kerrymarine.co.uk"
              >sam@kerrymarine.co.uk</a
            ><br />
            <mdb-icon fas icon="phone"></mdb-icon>&nbsp;&nbsp;Phone: Samantha:
            <a href="tel:07979498760">07979 498760</a>
          </p>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2">
          <h4 class="pt-2">Policies</h4>
          <p>
            <mdb-icon fas icon="ban"></mdb-icon>&nbsp;&nbsp;
            <a [routerLink]="'privacy'">Privacy</a><br />
          </p>
          <p>
            <mdb-icon fas icon="shield-alt"></mdb-icon>&nbsp;&nbsp;
            <a [routerLink]="'iso9001'">ISO9001</a><br />
          </p>
          <p>
            <mdb-icon fas icon="file-signature"></mdb-icon>&nbsp;&nbsp;
            <a [routerLink]="'terms'">Terms & Conditions</a><br />
          </p>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-4 mt-2">
          <div class="d-flex justify-content-around">
            <img
              class="footer-logos img-fluid"
              src="assets/kerry-images/safe-contractor-sticker.jpg"
            />
            <img
              class="footer-logos img-fluid"
              src="assets/kerry-images/Avetta-Member.png"
            />
            <img
              class="footer-logos img-fluid"
              src="assets/kerry-images/iso-9001-logo.jpg"
            />
          </div>
          <div class="d-flex justify-content-between mt-2">
            <img
              class="footer-logos img-fluid bg-white"
              src="assets/kerry-images/BritishMarineLogo.png"
            />
            <img
              class="footer-logos img-fluid"
              src="assets/kerry-images/alcumus-logo.jpg"
            />
          </div>
        </div>
      </div>
      <div class="footer-lower">
        <div class="media-container-row">
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
        <div class="row col-12 mbr-white pb-3">
          <div class="col-md-6 copyright">
            <p class="mbr-text">
              © Copyright {{ currentYear }} Kerry Marine Engineering Ltd - All
              Rights Reserved
            </p>
          </div>
          <div class="col-md-6">
            <p class="mbr-text">
              Website created by
              <a target="_blank" href="https://customwebsolutions.ltd"
                >Custom Web Solutions Ltd</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
