import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { IFeature } from 'src/app/models/IFeature';

@Component({
  selector: 'app-feature-cards',
  templateUrl: './feature-cards.component.html',
  styleUrls: ['./feature-cards.component.scss']
})
export class FeatureCardsComponent implements OnInit {

  @Input() title: string;

  @Input() message: string;

  @Input() features: IFeature[] = [];

  constructor() {}

  ngOnInit(): void {
  }

}
