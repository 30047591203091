import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITeam } from '../models/ITeam';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IContact } from '../models/IContact';
import { IFeature } from '../models/IFeature';
import { Photo } from '../models/Photo';

@Injectable({
  providedIn: 'root'
})


export class DataService {

  constructor(private http: HttpClient) { }

  GetTeams(): Observable<ITeam[]> {
    return this.http.get<ITeam[]>('data/team.json');
  }

  GetFeatures(): Observable<IFeature[]> {
    return this.http.get<IFeature[]>('data/feature.json');
  }

  GetPhotos(): Observable<Photo[]> {
    return this.http.get<Photo[]>('data/photobanner.json');
  }

  SendContactForm(contact: IContact) {
    const data = JSON.stringify(contact);
    return this.http.post<string>('https://57tcuymfij.execute-api.eu-west-1.amazonaws.com//mailFwd', data );
  }
}
