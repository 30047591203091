import { Component, OnInit, Input } from "@angular/core";
import { btnTypeEnum } from "src/app/enums/enums";

@Component({
  selector: "app-banner-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  constructor() {}

  @Input()
  title: string;

  @Input()
  message: string;

  @Input() opening: boolean = false;

  // Btn 1 always visible
  @Input()
  contact: string;
  @Input()
  btnType: btnTypeEnum;
  btnClass: string;
  bthHref: string;

  // Btn 2
  @Input()
  contact2: string;
  @Input()
  btnType2: btnTypeEnum;
  btnClass2: string;
  btnShow2: boolean;
  bthHref2: string;

  ngOnInit(): void {
    // set btn 1
    this.btnClass = this.GetClass(this.btnType);
    this.bthHref = this.GetHref(this.btnType, this.contact);
    // set btn 2
    this.btnShow2 = this.contact2 !== undefined && this.contact2 !== "";

    if (this.btnShow2) {
      this.btnClass2 = this.GetClass(this.btnType2);
      this.bthHref2 = this.GetHref(this.btnType2, this.contact2);
    }
  }
  GetClass(btnType: btnTypeEnum): string {
    let css: string;
    switch (btnType) {
      case btnTypeEnum.email:
        css = "btn stylish-color-dark";
        break;
      case btnTypeEnum.idLink:
        css = "btn stylish-color-dark";
        break;
      case btnTypeEnum.tel:
        css = "btn stylish-color-dark";
        break;
      default:
        css = "btn btn-danger";
        break;
    }
    return css;
  }
  GetHref(btnType: btnTypeEnum, btnContact: string): string {
    let href: string;
    switch (btnType) {
      case btnTypeEnum.email:
        href = "mailto:" + btnContact;
        break;
      case btnTypeEnum.idLink:
        href = "#" + btnContact;
        break;
      case btnTypeEnum.tel:
        href = "tel:" + btnContact;
        break;
      default:
        href = "#";
        break;
    }
    return href;
  }
}
