<svg viewBox="0 0 1127 600">
  <path id="top"
    style="display:inline;fill:#0c1229;fill-opacity:1" >
    <animate id="topAmim1" attributeName="d" dur="2000ms" [attr.from]="top" [attr.to]="topRnd" begin="0s; topAmim2.end"  fill="freeze" />
    <animate id="topAmim2" attributeName="d" dur="2000ms" [attr.from]="topRnd" [attr.to]="top" begin="topAmim1.end"  fill="freeze" />
  </path>

  <path  id="middle"
    style="display:inline;fill:#1f2f6a;fill-opacity:1">
    <animate id="middleAmim1" attributeName="d" dur="2000ms" [attr.from]="middle" [attr.to]="middleRnd" begin="0s; middleAmim2.end"  fill="freeze" />
    <animate id="middleAmim2" attributeName="d" dur="2000ms" [attr.from]="middleRnd" [attr.to]="middle" begin="middleAmim1.end"  fill="freeze" />
  </path>

  <path id="bottom"
    style="display:inline;fill:#896a36;fill-opacity:1">
    <animate id="bottomAmim1" attributeName="d" dur="2000ms" repeat="" [attr.from]="bottom" [attr.to]="bottomRnd" begin="0s; bottomAmim2.end" fill="freeze" />
    <animate id="bottomAmim2" attributeName="d" dur="2000ms" [attr.from]="bottomRnd" [attr.to]="bottom" begin="bottomAmim1.end"  />
  </path>
</svg>
