<div class="">
  <div class="p-5">
    <div class="card-deck">
      <div class="row mx-auto services-text">
      <div class="card col-12 col-md-6 text-center">
            <div class="card-box align-center p-4 my-auto">
               <p class="display-7">On and off site engineering<br><br>All steel work and construction<br><br>Full welding and fabrication services<br><br>Ship repairs and dry dock services<br><br>Installation of new equipment</p>

          </div>
        </div>

      <div class="card  col-12 col-md-6 text-center">
          <div class="card-box align-center p-4 my-auto">
              <p class="display-7">Vessel upgrades and vessel life extension<br><br>Hydraulic engineering<br><br>Scaffolding<br><br>Full blasting and painting services</p>
          </div>
      </div>
    </div>
  </div>
</div>
</div>
