<div class="item-box" (click)="GalleryItemClicked()">
  <img class="item-image rounded" [src]="safeSRC" />

  <div class="text-white overlay">
    <mdb-icon
      class="item-icon"
      fas
      size="3x"
      icon="expand-arrows-alt"
    ></mdb-icon>
    <mdb-icon class="item-tl" fas size="3x" icon="chevron-left"></mdb-icon>
    <mdb-icon class="item-tr" fas size="3x" icon="chevron-left"></mdb-icon>
    <mdb-icon class="item-bl" fas size="3x" icon="chevron-left"></mdb-icon>
    <mdb-icon class="item-br" fas size="3x" icon="chevron-left"></mdb-icon>
  </div>
</div>
