<div id="page" class="container-fluid">

  <app-header></app-header>

  <div id="main">

      <router-outlet></router-outlet>

  </div>

</div>

<app-footer></app-footer>

