import { Component, OnInit } from "@angular/core";
import reviews from "./../../../../data/reviews.json";

@Component({
  selector: "app-slidy-review",
  templateUrl: "./slidy-review.component.html",
  styleUrls: ["./slidy-review.component.scss"],
})
export class SlidyReviewComponent implements OnInit {
  constructor() {}

  get reviews() {
    return reviews;
  }

  ngOnInit(): void {}
}
