<div class="container page-top-margin">
  <div class="row mb-4">
    <div class="col-12 col-md-7 my-auto">
      <p class="py-2" *ngFor="let body of service[0].description">{{ body }}</p>
    </div>
    <div class="col-12 col-md-5">
      <div class="card shadow text-center bk text-white">
        <h2 class="pt-2 mb-2">Our services include:</h2>
        <div class="row">
          <div>
            <div class="text-left pe-4 my-auto">
              <ul [@listAnimation]>
                <li *ngFor="let extra of service[0].extraServices">
                  <div class="bullet-point bk-lt">
                    <i class="fas fa-wrench m-2 spin"></i> {{ extra }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
