<div class="container page-top-margin">
  <div>
    <div class="row featurette mb-2 wow fadeInRight" data-wow-delay="delay">
      <div class="col-md-7 order-md-2 my-auto">
        <h2 class="featurette-heading">
          Karl
          <span class="text-muted">Karls job title</span>
        </h2>
        <p class="lead">text about karl</p>
      </div>
      <div class="col-md-5 order-md-1">
        <svg
          class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          width="500"
          height="500"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder: 500x500"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#eee"></rect>
          <text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text>
        </svg>
      </div>
    </div>
    <div class="row featurette mb-2 wow fadeInLeft" data-wow-delay="delay">
      <div class="col-md-7 order-md-1 my-auto text-end">
        <h2 class="featurette-heading">
          Sam
          <span class="text-muted">Sams Job title</span>
        </h2>
        <p class="lead">text about Sam</p>
      </div>
      <div class="col-md-5 order-md-2">
        <svg
          class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          width="500"
          height="500"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder: 500x500"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#eee"></rect>
          <text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text>
        </svg>
      </div>
    </div>

    <div class="row featurette mb-2 wow fadeInRight" data-wow-delay="delay">
      <div class="col-md-7 order-md-2 my-auto">
        <h2 class="featurette-heading">
          Kane
          <span class="text-muted">kanes job title.</span>
        </h2>
        <p class="lead">text about kane</p>
      </div>
      <div class="col-md-5 order-md-1">
        <svg
          class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
          width="500"
          height="500"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder: 500x500"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#eee"></rect>
          <text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text>
        </svg>
      </div>
    </div>
  </div>
  <div class="mb-5"><app-contact-form></app-contact-form></div>
</div>
