<div class="content">
  <div class="container-fluid">
    <app-intro></app-intro>
    <!-- Main image full size -->
  </div>

  <div class="container-fluid hours-banner">
    <app-hours-banner></app-hours-banner>
  </div>

  <!-- hidden until news items provided -->
  <!-- <div class="container-fluid">
    <app-blog-mini-view></app-blog-mini-view>
  </div> -->

  <div id="AboutUs" class="container-fluid">
    <!-- This will be the abobut us section spread over three cards-->
    <app-feature-cards
      id="AboutUs"
      [title]="'All about us'"
      [message]="'What Kerry Marine Engineering Ltd stands for'"
      [features]="features1"
    ></app-feature-cards>
  </div>

  <div class="container-fluid">
    <!-- contact us banner-->
    <app-banner-contact
      [title]="'Opening times'"
      [message]="'We are open 24 hours a day, 7 days a week, 365 days a year!'"
      [btnType]="btnTypeEnum.email"
      [contact]="'Contact us'"
      [btnType2]="btnTypeEnum.tel"
      [contact2]=""
      [opening]="true"
    ></app-banner-contact>
  </div>

  <!-- new component container fluid 3 images propeller before during and after-->
  <div class="container-fluid">
    <app-photo-banner [photos]="photos"></app-photo-banner>
  </div>
  <!-- new component dry docking right side picture left side text-->
  <div class="container-fluid">
    <app-dry-d></app-dry-d>
  </div>
  <!-- new component simple banner with text-->
  <div class="container-fluid">
    <app-text-banner></app-text-banner>
  </div>

  <div class="container-fluid">
    <app-banner-parallax [bgImage]="'parallax.jpg'">
      <app-payoptions-content></app-payoptions-content>
      <!-- this will be the other services section in same layout-->
    </app-banner-parallax>
  </div>

  <div class="container-fluid">
    <!-- contact us banner-->
    <app-banner-contact
      [title]="
        'Call us now or fill in our contact form to discuss our services'
      "
      [message]=""
      [btnType]="btnTypeEnum.email"
      [contact]="'Contact us'"
      [btnType2]="btnTypeEnum.tel"
      [contact2]=""
    ></app-banner-contact>
  </div>

  <!-- new component left side picture right side text Project management section-->
  <div class="container-fluid">
    <app-project-m></app-project-m>
  </div>
  <!-- to be a card carousel -->

  <div id="Services" class="container-fluid">
    <app-feature-cards
      [title]="'Some of the services we offer'"
      [message]="
        '• On and off site engineering •	All steel work and construction •	Full welding and fabrication services •	Ship repairs and dry dock services •	Installation of new equipment •	Vessel upgrades and vessel life extension services •	Hydraulic engineering •	Scaffolding •	Full blasting and painting services'
      "
      [features]="features2"
    ></app-feature-cards>
  </div>

  <div class="container-fluid" id="WhoAreWe">
    <!--meet the team section-->
    <app-teams [title]="'The Team at Kerry Marine'" [message]=""></app-teams>
  </div>

  <!--hidden until more info recieved-->

  <!-- <div id="Services" class="container-fluid">
    <app-feature-cards
      [title]="'Some of the services we offer'"
      [message]="
        '• On and off site engineering •	All steel work and construction •	Full welding and fabrication services •	Ship repairs and dry dock services •	Installation of new equipment •	Vessel upgrades and vessel life extension services •	Hydraulic engineering •	Scaffolding •	Full blasting and painting services'
      "
      [features]="features3"
    ></app-feature-cards>
  </div> -->

  <div class="container-fluid" #ContactForm id="ContactForm">
    <app-contact-form></app-contact-form>
  </div>
</div>
