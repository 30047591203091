import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import "jarallax";
declare var jarallax: any;

@Component({
  selector: "app-banner-parallax",
  templateUrl: "./banner-parallax.component.html",
  styleUrls: ["./banner-parallax.component.scss"],
})
export class BannerParallaxComponent implements OnInit, AfterViewInit {
  @Input() bgImage: string;
  constructor() {}

  // @ViewChild('content', {read: ElementRef, static: false}) elementView: ElementRef;
  parallaxHeight: number;
  innerWidth: number;

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 544) {
      this.parallaxHeight = 700;
    } else if (this.innerWidth < 768) {
      this.parallaxHeight = 550;
    } else {
      this.parallaxHeight = 400;
    }
  }

  ngOnInit(): void {
    this.parallaxHeight = 600;
    this.onResize();
    if (this.bgImage === undefined || this.bgImage === "") {
      this.bgImage = "cashflow.jpg";
    }
  }
  ngAfterViewInit() {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }

  Style() {
    return (
      "background-image: url(assets/kerry-images/" +
      this.bgImage +
      "); background-attachment: fixed; height:" +
      this.parallaxHeight +
      "px;"
    );
  }
}
